import React from "react";
import styled from "styled-components";

interface IModel{
  image: string;
  title: string;
}

const Header = ({
  image,
  title
}: IModel) => {
  return (
    <Container image={image}>
      <HeaderShadow/>
      <HeaderTitle>{title}</HeaderTitle>
    </Container>
  );
}

export default Header;

//HEADER
const Container = styled.div`
  position: relative;
  height: 300px;
  background-color: #eee;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: 20%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeaderShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .6;
`;

const HeaderTitle = styled.h1`
  color: ${props => props.theme.color.white};
  font-size: 3.125rem;
  padding: 0;
  margin: 0;
  z-index: 9;
  font-family: 'Classical Authentic';
  font-weight: 500;

  @media(max-width: ${props => props.theme.queries.sm}){
    font-size: 2.125rem;
  }
`;
//