import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Pane } from "evergreen-ui";
import { Link } from "react-router-dom";

import Logo from "../assets/images/logo_dubaione_branco.png";
import Wpp from "../assets/images/wpp.webp";

//icons
import { Instagram } from "@styled-icons/bootstrap";

const Footer = () => {
  const { pathname } = useLocation();
  return (
    <div>
      {pathname !== "/" ? (
        <svg
          id="svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-200 0 950 110"
          style={{ marginBottom: "-10px" }}
        >
          <path
            d="M-314,267 C105,364 300,100 812,279"
            fill="none"
            stroke="#bfb56e"
            stroke-width="60%"
            stroke-linecap="round"
          />
        </svg>
      ) : null}
      <Container>
        <Content>
          <ColumnContent>
            <Logotype src={Logo} alt="dubai one" />
            <InfoDubaiOne marginTop="2rem">
              <InfoDubaiOneText>
                Dubai One um novo conceito de culinária direto de Dubai, a
                cidade mais extravagante do Oriente Médio, para você!
              </InfoDubaiOneText>
            </InfoDubaiOne>
          </ColumnContent>

          <ColumnContent>
            <TitleLinks>
              <div />
              <span>links</span>
            </TitleLinks>
            <ContentLinks>
              <Link to="/">Início</Link>
              <Link to="/menu">Menu</Link>
              <Link to="/bebidas">Bebidas</Link>
              <Link to="/sobre-nos">Sobre nós</Link>
              <Link to="/contato">Contato</Link>
            </ContentLinks>
          </ColumnContent>

          <ColumnContent>
            <TitleLinks>
              <div />
              <span>Informações</span>
            </TitleLinks>
            <InfoDubaiOne marginTop="1.3rem">
              <InfoDubaiOneText>
                Rua Anézia Nunes Matarazzo, 113, Vila Rubi - São José dos Campos
                | CEP: 12245-581
              </InfoDubaiOneText>
              <InfoDubaiOneText>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="https://wa.me/12976054029"
                  target="_blank"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    (12) 97605-4029
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px"
                      }}
                      src={Wpp}
                      alt="Whatsapp Dubai One"
                      draggable={false}
                    />
                  </div>
                </a>
                (12) 3913-2700 <br />
                (12) 3942-9455
              </InfoDubaiOneText>
              <InfoDubaiOneText>E-mail: adm@dubaione.com.br</InfoDubaiOneText>
              <InfoDubaiOneText>Terça a Sexta: 18:00 às 23:00</InfoDubaiOneText>
              <InfoDubaiOneText>Sábado: 16:00 às 23:00</InfoDubaiOneText>
              <InfoDubaiOneText>Domingo: 14:00 às 23:00</InfoDubaiOneText>
            </InfoDubaiOne>
          </ColumnContent>

          <ColumnContent>
            <BoxSocialMedia>
              <a
                href="https://www.instagram.com/dubaione.cucina/"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram height="40px" color="#fff" cursor="pointer" />
              </a>
            </BoxSocialMedia>
          </ColumnContent>
        </Content>
      </Container>
    </div>
  );
};

export default Footer;

const Container = styled.footer`
  z-index: 10;
  margin-top: -2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.theme.color.green};
  width: 100%;

  @media (max-width: ${props => props.theme.queries.md}) {
    margin-top: 0rem;
  }
`;

const Content = styled(Pane)`
  max-width: ${props => props.theme.queries.xl};
  padding: 55px 0;
  width: 100%;
  margin: 0 auto;

  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: 1fr 1fr 1fr 0.7fr;
  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 55px 1rem;
  }
  @media (max-width: ${props => props.theme.queries.md}) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5rem;
  }
  @media (max-width: ${props => props.theme.queries.sm}) {
    grid-gap: 1rem;
  }
`;

const Logotype = styled.img`
  width: 90px;
  height: 60px;
`;

const ColumnContent = styled(Pane)`
  display: flex;
  flex-direction: column;
`;

//INFO SIRIUS

const InfoDubaiOne = styled(Pane)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  div:first-child {
    margin-top: 0;
  }
`;

const InfoDubaiOneText = styled.div`
  color: ${props => props.theme.color.white};
  font-weight: 600;
  font-size: 1rem;
  margin-top: 0.3rem;
`;

//

const TitleLinks = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 2rem;
    height: 2px;
    background-color: ${props => props.theme.color.white};
  }

  span {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${props => props.theme.color.white};
    margin-left: 12px;
    text-transform: uppercase;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    div {
      display: none;
    }

    span {
      margin-left: 0;
    }
  }
`;

const ContentLinks = styled.div`
  margin-top: 1rem;
  margin-left: 45px;
  display: flex;
  flex-direction: column;

  a,
  div {
    color: ${props => props.theme.color.white};
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: none;
    margin-top: 0.2rem;
  }

  a {
    width: 200px;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    margin-left: 0;
  }
`;

//Social media

const BoxSocialMedia = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 50px;
  grid-gap: 17px;

  @media (max-width: ${props => props.theme.queries.md}) {
    grid-template-columns: repeat(4, 45px);
  }
`;
