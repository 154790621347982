export const theme = {
  color: {
    background: "#fff",
    black: "#1B1F22",
    gray: "#757267",
    lightGray: "#9B9B92",
    green: "#bfb56e",//bfb56e
    lightGreen: "#9CDB70",
    red: "#bf0e08",
    orange: "#FF9E01",
    white: "#fff",
    yellow: "#FBED65",
    paragraph: "#7f7f7f",//7f7f7f
    title: "#827c6e",//827c6e
  },
  boxShadow: "0px 6px 20px rgba(0,0,0,0.08)",
  queries: {
    i5: "320px",
    sm: "440px",
    md: "768px",
    lg: "1024px",
    xl: "1280px"
  }
};
