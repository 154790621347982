import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "theme";
import queryString from "query-string";
import { scroller } from "react-scroll";
import useWindowSize from "hooks/useWindowSize";
import foods from "utils/foods.json";

//components
import Header from "components/Header";
import GridOne from "components/Menu/GridOne";
import GridTwo from "components/Menu/GridTwo";
import GridThree from "components/Menu/GridThree";
import ModalFoodDetail from "components/Menu/ModalFoodDetail";

//assets
import backgroundImage from "assets/banner/dubai-cucina-10.jpg";

const Menu = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { width } = useWindowSize();
  const location = useLocation();

  const query = queryString.parse(location.search);
  const querySection = query.s || "";

  useEffect(() => {
    if (!!querySection) {
      scroller.scrollTo(querySection, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart"
      });
    }
  }, []);

  return (
    <Container>
      <Header title="Menu | Cardápio" image={backgroundImage} />
      <Content>
        {/* grid inline com margin-top */}
        <ContainerColor>
          <GridOne
            handleItem={item => {
              setShowDetail(true);
              setSelectedItem(item);
            }}
            data={foods.data.filter(item => item.section === 1)}
          />
        </ContainerColor>

        {/* grid coluna na esquerda */}
        <ContainerColor backgroundColor={theme.color.title} allColor={"#fff"}>
          <GridTwo
            handleItem={item => {
              setShowDetail(true);
              setSelectedItem(item);
            }}
            data={foods.data.filter(item => item.section === 2)}
          />
        </ContainerColor>

        {/* grid centro lado a lado */}
        {/* <ContainerColor>
          <GridThree
            handleItem={(item) => {
              setShowDetail(true);
              setSelectedItem(item);
            }}
            data={foods.data.filter((item) => item.section === 3)}
          />
        </ContainerColor> */}

        <ContainerColor>
          <GridOne
            handleItem={item => {
              setShowDetail(true);
              setSelectedItem(item);
            }}
            data={foods.data.filter(item => item.section === 4)}
          />
        </ContainerColor>

        <ContainerColor>
          <GridOne
            handleItem={item => {
              setShowDetail(true);
              setSelectedItem(item);
            }}
            data={foods.data.filter(item => item.section === 5)}
          />
        </ContainerColor>
      </Content>
      {width <= 768 ? (
        <ModalFoodDetail
          data={selectedItem}
          show={showDetail}
          onClose={() => {
            setShowDetail(false);
            setSelectedItem({});
          }}
        />
      ) : null}
    </Container>
  );
};

export default Menu;

const Container = styled.div``;

const Content = styled.div`
  padding: 4rem 0 0 0;
`;

const ContainerColor = styled.div`
  padding: 3rem 0 0 0;
  background-color: ${props => props.backgroundColor};

  * {
    color: ${props => props.allColor} !important;
  }
`;
