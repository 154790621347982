import React from "react";
import styled from "styled-components";
import desenho from "assets/icons/desenho.svg";

const GridOne = ({handleItem, data}) => {
  return (
    <Grid>
      {
        data?.map((item,idx) => (
          <div key={idx} className="container-items">
            <BoxListTitle>
              <ListTitle>{item.title}</ListTitle>
            </BoxListTitle>
            <List>
              {
                item?.childrens?.map((child, child_idx) => (
                  <ListItem key={child_idx} onClick={() => {
                    if(child?.image){
                      handleItem(child);
                    }
                    }}>
                    <Column>
                      <div>{child.title}</div>
                      <span>{child.description}</span>
                      {
                        child?.childrens?.map((i, i_idx) => (
                          <span key={i_idx}>{i.title}</span>
                        ))
                      }
                    </Column>
                    {!!child?.image ? (
                      <img
                        src={`${process.env.REACT_APP_IMAGES}/${child?.image}`}
                        alt="Aqui tem uma imagem"
                      />
                    ) : null}
                  </ListItem>
                ))
              }
            </List>
          </div>
        ))
      }
      <img src={desenho} alt="desenho" className="desenho" draggable={false} />
    </Grid>
  )
}

export default GridOne;

const Grid = styled.div` 
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;
  position: relative;
  z-index: 9;

  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  .container-items:nth-child(4){
    margin-top: -170px;
  }

  .container-items:nth-child(5){
    margin-top: -540px;
  }

  .container-items:first-child{
    margin-top: 0;
  }

  .container-items{
    z-index: 9;
  }

  ul{
    max-width: 600px;
  }

  .desenho{
    position: absolute;
    top: 100px;
    left: 30px;
    max-width: 90%;
    opacity: .7;
  }

  @media(max-width: ${props => props.theme.queries.xl}){
    padding: 0 1rem;
  }

  @media(max-width: ${props => props.theme.queries.md}){
    grid-template-columns: repeat(1, 100%);

    .container-items{
      margin-top: 0 !important;
    }

    ul{
      max-width: 100%;
    }
  }
`;

//TITLE

const BoxListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;

  @media(max-width: ${props => props.theme.queries.sm}){
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
`;

const ListTitle = styled.h2`
  text-align: left;
  font-size: 3.2rem;
  margin: 0 0 1rem 0;
  color: ${props => props.theme.color.green};
  font-family: 'Classical Authentic';
  font-weight: 400;
  line-height: 1.3;

  @media(max-width: ${props => props.theme.queries.sm}){
    font-size: 2rem;
    line-height: 1.1;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 3rem 0 ;
  display: grid; 
  grid-template-columns: repeat(1, 100%);
  grid-gap: 20px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    display: none;
    width: 55px;
    height: 84px;
    margin-left: 1rem;
    border-radius: .3rem;
  }

  @media(max-width: ${props => props.theme.queries.md}){
    img {
      display: block;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  div {
    color: ${props => props.theme.color.title};
    font-size: 2.375rem;
    font-weight: 400;
    font-family: 'Classical Authentic';
    line-height: 1.2;
  }

  span {
    color: ${props => props.theme.color.paragraph};
    font-size: 1.25rem;
    line-height: 1.6;
  }

  @media(max-width: ${props => props.theme.queries.sm}){
    div {
      font-size: 1.375rem;
    }
    span {
      font-size: .9rem;
    }
  }
`;