import abobrinha from "assets/entradas/abobrinha.jpg";
import babaganuche from "assets/entradas/babaganuche.jpg";
import coalhadaseca from "assets/entradas/coalhadaseca.jpg";
import homus from "assets/entradas/homus.jpg";

import tabule from "assets/saladas/tabule.jpeg";
import saladadubai from "assets/saladas/salada_dubai.jpeg";
import saladaone from "assets/saladas/sala_one.jpeg";

import beirutepresunto from "assets/beirute/beiture.jpeg";
import beirutequeijo from "assets/beirute/beiture.jpeg";

import kibeassado from "assets/kibe/kibe_assado.jpeg";
import kibefrito from "assets/kibe/kibe_frito.jpeg";
import kibecru from "assets/kibe/kibe_cru.png";

import kebabkafta from "assets/kebab/kebabkafta.jpg";
import kebabfrango from "assets/kebab/kebabfrango.jpg";

import sfihafechada from "assets/sfiha/sfiha_fechada.png";
import sfihascarne from "assets/sfiha/sfiha_carne.jpeg";
import sfihaverdura from "assets/sfiha/sfiha_verdura.jpeg";

import michuidefrango from "assets/grelhados/michuidefrango.jpg";
import michuidemignon from "assets/grelhados/michui_mignon.jpeg";
import kafta from "assets/grelhados/kafta.jpeg";

import abobrinharecheada from "assets/sugestoes/abobrilha_recheada.jpeg";
import charutinhodefolhadeuva from "assets/sugestoes/charutinho_folha_uva.jpeg";
import charutinhoderepolho from "assets/sugestoes/charutinho_repolho.jpeg";

const getUrlImage = title => {
  switch (title) {
    case "Abobrinha":
      return abobrinha;
    case "Babaganuche":
      return homus;
    case "Coalhada seca":
      return coalhadaseca;
    case "Homus":
      return babaganuche;
    case "Tabule":
      return tabule;
    case "Salada Dubai":
      return saladadubai;
    case "Salada One":
      return saladaone;
    case "Beirute de Presunto":
      return beirutepresunto;
    case "Beirute de Rosbife":
      return beirutepresunto;
    case "Beirute de Peru":
      return beirutepresunto;
    case "Beirute de Queijo":
      return beirutequeijo;
    case "Beirute de Kafta":
      return beirutequeijo;
    case "Kibe Cru":
      return kibecru;
    case "Kibe assado":
      return kibeassado;
    case "Kibe Frito":
      return kibefrito;
    case "Kebab de Frango":
      return kebabfrango;
    case "Kebab de Kafta":
      return kebabkafta;
    case "Sfiha de verdura":
      return sfihaverdura;
    case "Sfiha aberta Carne":
      return sfihascarne;
    case "Sfiha fechada":
      return sfihafechada;
    case "Michui de Frango":
      return michuidefrango;
    case "Michui de Mignon":
      return michuidemignon;
    case "Kafta":
      return kafta;
    case "Abobrinha recheada (2 un.)":
      return abobrinharecheada;
    case "Charutinho de folha de uva (8 un.)":
      return charutinhodefolhadeuva;
    case "Charutinho de repolho (12 un.)":
      return charutinhoderepolho;
  }
};

export { getUrlImage };
