import React, { useState, useEffect } from "react";
import styled from "styled-components";
import drinks from "utils/drinks.json";
import useWindowSize from "hooks/useWindowSize";

//components
import Header from "components/Header";
import GridOne from "components/Drinks/GridOne";
import ModalFoodDetail from "components/Menu/ModalFoodDetail";

//assets
import backgroundImage from "assets/images/drinks.jpg";

const Drinks = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { width } = useWindowSize();

  return (
    <Container>
      <Header title="Bebidas" image={backgroundImage} />
      <Content>
        <ContainerColor>
          <GridOne
            handleItem={item => {
              setShowDetail(true);
              setSelectedItem(item);
            }}
            data={drinks.data}
          />
        </ContainerColor>
      </Content>
      {width <= 768 ? (
        <ModalFoodDetail
          data={selectedItem}
          show={showDetail}
          onClose={() => {
            setShowDetail(false);
            setSelectedItem({});
          }}
        />
      ) : null}
    </Container>
  );
};

export default Drinks;

const Container = styled.div``;

const Content = styled.div`
  padding: 4rem 0 0 0;
`;

const ContainerColor = styled.div`
  padding: 3rem 0;
  background-color: ${props => props.backgroundColor};
`;
