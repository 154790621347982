import React from "react";
import styled from "styled-components";
import ReactGA from "react-ga";

//assets
import mapa from "assets/images/map.png";

const Location = () => {
  return (
    <Container
      onClick={() => {
        ReactGA.event({
          category: "mapa",
          action: "clicou no mapa"
        });
        window.open(
          "https://www.google.com/maps/place/R.+An%C3%A9sia+Nunes+Matarazzo,+113+-+Vila+Rubi,+S%C3%A3o+Jos%C3%A9+dos+Campos+-+SP,+12245-581/@-23.2037103,-45.8937836,17z/data=!3m1!4b1!4m6!3m5!1s0x94cc4a6f62037055:0xfcf784d2e47449a2!8m2!3d-23.2037103!4d-45.8912033!16s%2Fg%2F11c2gr33x4?entry=ttu",
          "_blank"
        );
      }}
      url={mapa}
    ></Container>
  );
};

export default Location;

const Container = styled.div`
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
  cursor: pointer;

  height: 350px;
`;
