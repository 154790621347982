import React from "react";
import styled from "styled-components";
import { Pane } from "evergreen-ui";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//assets
import cucina1 from "assets/banner/dubai-cucina-10.jpg";
import cucina2 from "assets/banner/dubai-cucina-56.jpg";
import cucina3 from "assets/banner/dubai-cucina-64.jpg";
import cucina4 from "assets/banner/dubai-cucina-77.jpg";
import cucina5 from "assets/banner/dubai-cucina-28.jpg";
import cucina6 from "assets/banner/dubai-cucina-109.jpg";
import cucina7 from "assets/banner/dubai-cucina-118.jpg";
import cucina8 from "assets/banner/dubai-cucina-169.jpg";

const Banner = () => {

  const responsive = {
    default: {
      breakpoint: { max: 4000, min: 0 },
      items: 1
    }
  };

  return (
    <Container>
      <CustomCarousel 
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={6000}
        infinite={true}
      >
        <CarouselItem url={cucina2}></CarouselItem>
        <CarouselItem url={cucina1}></CarouselItem>
        <CarouselItem url={cucina5}></CarouselItem>
        <CarouselItem url={cucina3}></CarouselItem>
        <CarouselItem url={cucina4}></CarouselItem>
        <CarouselItem url={cucina6}></CarouselItem>
        <CarouselItem url={cucina7}></CarouselItem>
        <CarouselItem url={cucina8}></CarouselItem>
      </CustomCarousel>
    </Container>
  );
};

export default Banner;

const Container = styled(Pane)`
  height: 600px;
  width: 100%;
  background-color: #eee;

  @media(max-width: ${props => props.theme.queries.md}){
    height: 300px;
  }
`;

const CustomCarousel = styled(Carousel)`
  height: 100%;

  li {
    height: 600px !important;
    cursor: grab;
  }

  .react-multiple-carousel__arrow{
    z-index: 10 !important;
  }

  .react-multiple-carousel__arrow--left{
    left: calc(1% + 1px) !important;
  }

  .react-multiple-carousel__arrow--right{
    right: calc(1% + 1px) !important;
  }

  @media(max-width: ${props => props.theme.queries.md}){
    li {
      height: 300px !important;
    }
  }
`;

const CarouselItem = styled.div`
  height: 100%;
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${props => props.position || "20%"};
`;