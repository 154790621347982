import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import styled from "styled-components";
import { SideSheet, Position, Icon, CrossIcon } from "evergreen-ui";

const Sidebar = (props: any) => {
  const { push } = useHistory();

  const handleRoutes = (route: string) => {
    push(route);
    props.closeSidebar();
  };

  return (
    <CustomSidebar
      width="100%"
      position={Position.TOP as any}
      isShown={props.show}
      onCloseComplete={props.closeSidebar}
    >
      <Container>
        <Content>
          <BoxLogotype>
            <Icon
              icon={CrossIcon}
              size={32}
              color={"#6A7380"}
              marginLeft={0}
              marginRight={0}
              cursor="pointer"
              onClick={props.closeSidebar}
            />
          </BoxLogotype>
          <NavBox>
            <ul>
              <li onClick={() => handleRoutes("/")}>Início</li>
              <li onClick={() => handleRoutes("/menu")}>Menu</li>
              <li onClick={() => handleRoutes("/bebidas")}>Bebidas</li>
              <li>
                <a
                  href="dubaione_cardapio.pdf"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#827c6e" }}
                >
                  Cardápio
                </a>
              </li>
              <li onClick={() => handleRoutes("/sobre-nos")}>Sobre nós</li>
              <li onClick={() => handleRoutes("/contato")}>Contato</li>
            </ul>
          </NavBox>
        </Content>
      </Container>
    </CustomSidebar>
  );
};

export default withRouter(Sidebar);

const CustomSidebar = styled(SideSheet)`
  z-index: 99999 !important;
`;

const Container = styled.div`
  overflow-y: auto;
  height: 100vh;
  z-index: 100;
  z-index: 99999 !important;
`;

const Content = styled.div`
  display: block;
  padding: 1rem;
`;

const NavBox = styled.nav`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    list-style: none;
    padding: 0 1rem;
    margin: 0;
  }

  li {
    font-size: 2rem;
    font-weight: 600;
    color: ${props => props.theme.color.title};
    margin-top: 0.7rem;
    cursor: pointer;
    text-align: center;
  }
`;

//HEADER

const BoxLogotype = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${props => props.theme.queries.md}) {
    width: 100%;
  }
`;
