import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { Layout } from "../components/Layout";

import Home from "pages/home";
import Contact from "pages/contact";
import AboutUs from "pages/aboutUs";
import Menu from "pages/menu";
import Drinks from "pages/drinks";

const Routes = ({ history }) => (
  <Router history={history}>
    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/contato" component={Contact} />
        <Route exact path="/menu" component={Menu} />
        <Route exact path="/bebidas" component={Drinks} />
        <Route exact path="/sobre-nos" component={AboutUs} />
      </Switch>
    </Layout>
  </Router>
);

export default Routes;
