import React from "react";
import styled from "styled-components";
import ReactGA from "react-ga";

const Delivery = () => {
  return (
    <Container>
      <Title>
        <h2>Delivery</h2>
      </Title>
      <h3>Peça também pelo</h3>
      <Grid>
        <div
          onClick={() => {
            ReactGA.event({
              category: "delivery",
              action: "button goomer"
            });
            window.open("https://www.goomer.app/dubai-one", "_blank");
          }}
        >
          <Image
            src="https://assets.website-files.com/5e21a45cae731c24d9d49a36/5e8691a3e8d859a0ae2fae03_logo-goomer.svg"
            alt="Goomer"
          />
        </div>
        <div
          onClick={() => {
            ReactGA.event({
              category: "delivery",
              action: "button ifood"
            });
            window.open(
              "https://www.ifood.com.br/delivery/sao-paulo-sp/dubai-one-vila-andrade/e845f190-0559-4106-9cb8-baba517f52d9",
              "_blank"
            );
          }}
        >
          <svg
            width="200"
            height="100"
            viewBox="0 0 80 43"
            fill="#ea1d2c"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 24.258h6.09L9.498 7.472h-6.09L0 24.258zM3.947 4.836h6.087L11.016 0H4.922l-.975 4.836zM8.498 28.985h6.09l3.695-18.167h4.444l.7-3.346h-4.482l.154-.776c.286-1.431.751-3.096 3.047-3.096 1.361 0 2.665.148 3.93.68L26.77.744A13.22 13.22 0 0 0 22.381 0c-4.894 0-8.265 2.914-9.499 7.472h-2.114l-.662 3.346h2.084L8.498 28.985z"></path>
            <path d="M27.79 24.633c7.19 0 12.18-6.569 12.18-11.927 0-3.902-3.688-5.608-7.195-5.608-7.89 0-12.183 7.063-12.183 11.927 0 3.902 3.726 5.608 7.198 5.608zM47 24.633c7.192 0 12.181-6.569 12.181-11.927 0-3.902-3.696-5.608-7.199-5.608-7.886 0-12.186 7.063-12.186 11.927 0 3.902 3.73 5.608 7.202 5.608M69.082 24.258h6.055L80 .374h-6.09l-1.451 7.097a12.51 12.51 0 0 0-2.46-.281c-5.966 0-11.391 7.588-11.391 13.011 0 2.231 1.45 4.43 3.912 4.43 3.5 0 5.921-1.712 6.932-3.317h.378l-.748 2.944zM50.322 36.91c-4.454 3.792-10.408 5.739-16.879 5.337-8.16-.502-13.816-6.82-14.954-13.227h.387c1.836 4.091 6.304 7.869 11.911 8.49 5.475.604 12.184-1.87 15.866-5.402l-4.13-3.124 12.263.036-2.639 12.983-1.825-5.093z"></path>
          </svg>
        </div>
        <div
          onClick={() => {
            ReactGA.event({
              category: "delivery",
              action: "button uber eats"
            });
            window.open("https://www.ubereats.com", "_blank");
          }}
        >
          <Image
            src="https://d3i4yxtzktqr9n.cloudfront.net/web-eats-v2/ee037401cb5d31b23cf780808ee4ec1f.svg"
            alt=""
          />
        </div>
      </Grid>
    </Container>
  );
};

export default Delivery;

const Container = styled.div`
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;
  padding: 4rem 0 6rem 0;

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 4rem 1rem 6rem 1rem;
  }
  @media (max-width: ${props => props.theme.queries.sm}) {
    h3 {
      text-align: center;
    }
  }
`;

const Title = styled.div`
  h2 {
    font-size: 3rem;
    margin: 0;
    padding: 0;
    color: ${props => props.theme.color.title};
    text-align: center;
  }
`;

const Grid = styled.div`
  margin-top: 1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;

  a {
    text-decoration: none;
  }

  svg {
    cursor: pointer;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    a {
      margin-top: 3rem;
    }
  }
`;

const Image = styled.img`
  width: 300px;
  height: auto;
  cursor: pointer;
`;
