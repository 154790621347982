import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import coalhada_seca from "assets/home/coalhada_seca.jpg";
import tabule from "assets/home/tabule.jpg";
import beirute from "assets/home/beirute.jpg";
import kibe_assado from "assets/home/kibe_assado.jpg";
import sfihas from "assets/home/sfihas.jpg";
import michui_de_file_mignon from "assets/home/michui_de_file_mignon.jpg";

const Categories = ({ data }) => {
  const { replace } = useHistory();

  const getUrlImage = title => {
    switch (title) {
      case "Entradas":
        return coalhada_seca;
      case "Saladas":
        return tabule;
      case "Beirutes":
        return beirute;
      case "Kibes":
        return kibe_assado;
      case "Sfihas":
        return sfihas;
      case "Grelhados da casa":
        return michui_de_file_mignon;
    }
  };

  return (
    <Container>
      <Title>
        <h2>Categorias</h2>
      </Title>
      <Grid>
        {data?.map((item, idx) => (
          <Card
            url={getUrlImage(item?.title)}
            key={idx}
            onClick={() => {
              replace({
                pathname: "/menu",
                search: `?s=${item?.title}`
              });
            }}
          >
            <div className="img" />
            <CardBoxContent>
              <CardContent>
                <CardTitle>{item.title}</CardTitle>
                <CardDescription>
                  {item.childrens?.map(item => item.title).join(", ")}
                </CardDescription>
              </CardContent>
            </CardBoxContent>
          </Card>
        ))}
      </Grid>
    </Container>
  );
};

export default Categories;

const Container = styled.div`
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;
  padding: 4rem 0 6rem 0;

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 4rem 1rem 6rem 1rem;
  }
`;

const Title = styled.div`
  h2 {
    font-size: 3rem;
    margin: 0;
    padding: 0;
    color: ${props => props.theme.color.title};
    text-align: center;
  }
`;

const Grid = styled.div`
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  a {
    text-decoration: none;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 30px;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
`;

const Card = styled.div`
  position: relative;
  height: 160px;
  cursor: pointer;

  .img {
    position: absolute;
    left: 0;
    bottom: 10px;
    border-radius: 10rem;
    background-image: url(${props => props.url});
    background-position: center;
    background-size: cover;
    width: 110px;
    height: 110px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    .img {
      width: 100px;
      height: 100px;
    }
  }
`;

const CardBoxContent = styled.div`
  box-shadow: 0 10px 25px rgba(0, 0, 0, 10%), 0 6px 6px rgba(0, 0, 0, 10%);
  margin-left: 50px;
  height: 100%;
  border-radius: 0.5rem;

  @media (max-width: ${props => props.theme.queries.sm}) {
    margin-left: 40px;
  }
`;

const CardContent = styled.div`
  margin-left: 60px;
  padding: 1.3rem 1rem;
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
`;

const CardTitle = styled.div`
  color: ${props => props.theme.color.title};
  font-size: 1.375rem;
  font-weight: 600;
  line-height: normal;

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 1.25rem;
  }
`;

const CardDescription = styled.span`
  margin-top: 0.3rem;
  color: ${props => props.theme.color.paragraph};
  font-size: 1rem;

  @media (max-width: ${props => props.theme.queries.i5}) {
    font-size: 0.9rem;
  }
`;
