import React from "react";
import styled from "styled-components";

//assets
import backgroundImage from "assets/images/about-us.jpg"

//components

const AboutUs = () => {
  return (
    <Container>
      <Header>
        <HeaderShadow />
        <HeaderTitle>Sobre nós</HeaderTitle>
      </Header>
      <ContainerGray>
        <OurHistory>Nossa história</OurHistory>
        <Paragraphs>
          Os ideais do restaurante Dubai One surgiram no ano de 2018 com a
          junção das maiores culinárias do mundo em apenas um local. O sonho de
          um novo conceito de culinária nasceu com José Rodrigues e Gil, ambos
          com mais de 25 anos de experiência à frente de redes de restaurantes
          árabes e italiano no estado de São Paulo. Gabriel Rodrigues, conhecido
          como Sheik brasileiro, após diversas idas ao oriente médio reascendeu
          a ideia e o sonho de seu pai José e seu tio Gil a fazer algo inovador
          e uma culinária completamente fora do normal e, por ter uma paixão
          enorme por Dubai, criou o nome e a marca Dubai One
          <br />
          <br />
          Dubai One um novo conceito de culinária direto de Dubai, a cidade mais
          extravagante do Oriente Médio, para você!
          <br />
          <br />
          Bom apetite.
        </Paragraphs>
      </ContainerGray>
      <ContentTime>
        <ColContentTime>
          <div>11:00 às 23:00</div>
          <span>Todos os dias</span>
        </ColContentTime>
      </ContentTime>
    </Container>
  );
};

export default AboutUs;

const Container = styled.div``;

//HEADER
const Header = styled.div`
  position: relative;
  height: 300px;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: 100% 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeaderShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
`;

const HeaderTitle = styled.h1`
  color: ${props => props.theme.color.white};
  font-size: 3.125rem;
  padding: 0;
  margin: 0;
  z-index: 9;
  font-family: "Classical Authentic";
  font-weight: 500;

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 2.125rem;
  }
`;
//

//CONTAINER GRAY
const ContainerGray = styled.div`
  background-color: #f7f7f7;
  padding: 4rem 0 5rem 0;

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 4rem 1rem 5rem 1rem;
  }
`;

const OurHistory = styled.h2`
  color: ${props => props.theme.color.title};
  text-align: center;
  font-size: 3.312rem;
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: "Classical Authentic";
  font-weight: 500;

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 2.125rem;
  }
`;

const Paragraphs = styled.div`
  max-width: 600px;
  margin: 1.5rem auto 0 auto;
  text-align: center;
  font-size: 1.125rem;
  color: ${props => props.theme.color.paragraph};
`;

//

const ContentTime = styled.div`
  background-color: ${props => props.theme.color.white};
  padding: 4rem 0;
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 100px;

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 4rem 1rem;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    flex-direction: column;
    grid-gap: 50px;
  }
`;

const ColContentTime = styled.div`
  display: flex;
  flex-direction: column;

  div {
    font-weight: 700;
    font-size: 1.625rem;
    color: ${props => props.theme.color.title};
    text-align: center;
  }

  span {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: ${props => props.theme.color.paragraph};
    text-align: center;
  }
`;
