import React from "react";
import styled from "styled-components";
import { TextInput, Textarea, RadioGroup, Pane } from "evergreen-ui";
import { useFormik } from "formik";
import InputMask from "react-input-mask";

const SectionForm = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      whatsapp: "",
      help: ""
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      const phone = values.whatsapp
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "");
      window.open(
        `https://api.whatsapp.com/send?phone=5511911220550&text=%20*Bem-vindo ao Dubaione*%0A---------------------------------------%0A%0A*${values.name} / ${values.email}*%0A(55)%20${phone}%0A%0A${values.help}`,
        "_blank"
      );
      formik.resetForm();
    },
    validate: values => {
      let errors = {} as any;

      if (!values.name) {
        errors.name = `o campo "nome" é obrigatório.`;
      }
      if (!values.email) {
        errors.email = `o campo "e-mail" é obrigatório.`;
      }
      if (!values.help) {
        errors.help = `o campo "como podemos ajudar?" é obrigatório.`;
      }

      return errors;
    }
  });

  return (
    <Container>
      <Title>Preencha o formulário e entraremos em contato:</Title>
      <Form onSubmit={formik.handleSubmit}>
        <RowInputs>
          <div>
            <TextInput
              height="45px"
              placeholder="Nome"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            <MessageError>
              {formik.errors.name && formik.touched.name && formik.errors.name}
            </MessageError>
          </div>
          <div>
            <TextInput
              height="45px"
              placeholder="E-mail"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <MessageError>
              {formik.errors.email &&
                formik.touched.email &&
                formik.errors.email}
            </MessageError>
          </div>
        </RowInputs>

        <RowInputs marginTop="30px">
          <CustomInputMask
            name="whatsapp"
            mask="(99) 99999-9999"
            value={formik.values.whatsapp}
            onChange={formik.handleChange}
            placeholder="Whatsapp"
          />
        </RowInputs>

        <div>
          <CustomTextarea
            paddingTop="1.3rem"
            marginTop="2.3rem"
            name="help"
            placeholder="Como podemos te ajudar?"
            height="220px"
            value={formik.values.help}
            onChange={formik.handleChange}
          />
          <MessageError>
            {formik.errors.help && formik.touched.help && formik.errors.help}
          </MessageError>
        </div>

        <SendButton type="submit">Enviar</SendButton>
      </Form>
    </Container>
  );
};

export default SectionForm;

const Container = styled.section`
  padding: 60px 0 120px 0;
  max-width: ${props => props.theme.queries.md};
  margin: 0 auto;

  @media (max-width: ${props => props.theme.queries.md}) {
    padding: 60px 1rem 60px 1rem;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 2rem;
  text-align: left;
  color: ${props => props.theme.color.subtitle};
  margin: 0;

  @media (max-width: ${props => props.theme.queries.md}) {
    font-size: 1.8rem;
    text-align: left;
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    font-size: 1.5rem;
  }
`;

const Form = styled.form`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
`;

const RowInputs = styled(Pane)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 20px;

  input {
    width: -webkit-fill-available;
    text-indent: 10px;
    border: 1px solid #eee;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    grid-gap: 30px;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
`;

const CustomRadioGroup = styled(RadioGroup)`
  label {
    margin-right: 1rem;
  }
`;

const CustomInputMask = styled(InputMask)`
  height: 40px;
  border: 1px solid #eee;
  border-radius: 0.3rem;
  box-shadow: inset 0 0 0 1px rgb(67 90 111 / 30%),
    inset 0 1px 2px rgb(67 90 111 / 14%);

  :focus {
    outline: none;
    box-shadow: inset 0 0 2px rgb(67 90 111 / 14%), inset 0 0 0 1px #579ad9,
      0 0 0 3px rgb(16 112 202 / 14%);
  }

  ::placeholder {
    color: #b8bfc8;
  }
`;

const CustomTextarea = styled(Textarea)`
  border: 1px solid #eee;
  font-family: "Gotham Book";
  font-size: 1rem;
`;

const SendButton = styled.button`
  background-color: ${props => props.theme.color.green};
  color: ${props => props.theme.color.white};
  border-radius: 2rem;
  max-width: 383px;
  width: 100%;
  height: 40px;
  outline: none;
  margin-top: 30px;
  border: none;
  text-align: center;
  font-size: 1.2rem;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
  align-self: center;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    width: 100%;
  }
`;

const MessageError = styled.div`
  transition: 0.2s ease-in-out;
  color: ${props => props.theme.color.red};
  font-size: 0.9rem;
  margin-bottom: 5px;
`;
