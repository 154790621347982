import React from "react";
import styled from "styled-components";

//assets
import backgroundImage from "assets/images/contact.jpg"

const SectionHeader = () => {
  return (
    <Header>
      <HeaderShadow/>
      <HeaderTitle>Vamos conversar ?</HeaderTitle>
    </Header>
  )
}

export default SectionHeader;

//HEADER
const Header = styled.div`
  position: relative;
  height: 300px;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: 100% 60%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeaderShadow = styled.div`
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .6;
`;

const HeaderTitle = styled.h1`
  color: ${props => props.theme.color.white};
  font-size: 3.125rem;
  padding: 0;
  margin: 0;
  z-index: 9;
  font-family: 'Classical Authentic';
  font-weight: 500;
  
  @media(max-width: ${props => props.theme.queries.sm}){
    font-size: 2.125rem;
  }
`;
//