import React from "react";
import styled from "styled-components";
import { Element } from "react-scroll";

import folha from "assets/icons/folha.svg";
import desenho from "assets/icons/desenho.svg";
import { getUrlImage } from "utils/images";

const GridTwo = ({ handleItem, data }) => {
  return (
    <GridColumn>
      {data?.map((item, idx) => (
        <div key={idx} className="container-items">
          <Element name={item?.title}>
            <BoxListTitle>
              <ListTitle>{item?.title}</ListTitle>
            </BoxListTitle>
            <List>
              {item?.childrens?.map((child, child_idx) => (
                <ListItem onClick={() => handleItem(child)} key={child_idx}>
                  <Column>
                    <div>
                      {child?.title}
                      {child?.type === 1 ? (
                        <img src={folha} alt="folha" draggable={false} />
                      ) : child?.type === 2 ? (
                        <>
                          <img src={folha} alt="folha" draggable={false} />
                          <img src={folha} alt="folha" draggable={false} />
                        </>
                      ) : null}
                    </div>
                    <span>{child?.description}</span>
                  </Column>
                  {!!child?.image ? (
                    <img
                      src={getUrlImage(child?.title)}
                      alt="Aqui tem uma imagem"
                    />
                  ) : null}
                </ListItem>
              ))}
            </List>
          </Element>
        </div>
      ))}
      <img src={desenho} alt="desenho" className="desenho" draggable={false} />
    </GridColumn>
  );
};

export default GridTwo;

const GridColumn = styled.div`
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;
  position: relative;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(1, 100%);

  .container-items {
    z-index: 9;
  }

  div[name="Saladas"] {
    margin-top: 1rem;
  }

  .desenho {
    position: absolute;
    top: 30%;
    left: 30px;
    max-width: 90%;
    opacity: 0.2;
  }

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 0 1rem;
  }
`;

//TITLE
const BoxListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;

  @media (max-width: ${props => props.theme.queries.sm}) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
`;

const ListTitle = styled.h2`
  text-align: left;
  font-size: 3.5rem;
  margin: 0 0 1rem 0;
  color: ${props => props.theme.color.green};
  font-family: "Classical Authentic";
  font-weight: 400;

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 2.5rem;
    line-height: 1.1;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 3rem 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    display: none;
    width: auto;
    height: 104px;
    margin-left: 1rem;
    border-radius: 0.3rem;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    img {
      display: block;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  div {
    display: flex;
    align-items: baseline;
    color: ${props => props.theme.color.title};
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.3;
    font-family: "Classical Authentic";

    img {
      width: 30px;
      height: 25px;
      margin-left: 0.3rem;
      display: block !important;
    }
  }

  span {
    color: ${props => props.theme.color.paragraph};
    font-size: 1.25rem;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    div {
      font-size: 1.275rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
`;
