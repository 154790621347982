import React from "react";
import styled from "styled-components";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Icon, Pane } from "evergreen-ui";

//components
import Banner from "./Banner";
import Logo from "../../assets/images/logo_dubaione.png";

const Navbar = props => {
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <>
      <Content>
        <ContentBox>
          <ContainerIcon>
            <Icon
              icon={props.icon}
              size={28}
              color={"#6A7380"}
              marginLeft={0}
              marginRight={0}
              onClick={props.displaySidebar}
            />
          </ContainerIcon>
          <ContainerLinks>
            <Link to="/menu">Menu</Link>
            <Link to="/bebidas">Bebidas</Link>
            <a href="dubaione_cardapio.pdf" target="_blank">
              Cardápio
            </a>
            {/* <Link to="/cardapio">Cardápio</Link> */}
            <Logotype src={Logo} alt="Logo" onClick={() => history.push("/")} />
            <Link to="/sobre-nos">Sobre nós</Link>
            <Link to="/contato">Contato</Link>
          </ContainerLinks>
        </ContentBox>
      </Content>
      {pathname === "/" ? <Banner /> : null}
    </>
  );
};

export default Navbar;

const Content = styled.div`
  height: 120px;
  background: ${props => props.theme.color.white};
`;

const ContentBox = styled.div`
  position: relative;
  max-width: ${props => props.theme.queries.xl};
  padding: 0 1rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Logotype = styled.img`
  width: auto;
  height: 100px;
  padding-left: 5rem;
  cursor: pointer;
  @media (max-width: ${props => props.theme.queries.md}) {
    padding-left: 2rem;
  }
`;

const ContainerIcon = styled.div`
  position: absolute;
  left: 1rem;
  height: 23px;
  cursor: pointer;
  display: none;

  @media (max-width: ${props => props.theme.queries.md}) {
    display: block;
  }
`;

//links

const ContainerLinks = styled.nav`
  display: flex;
  align-items: center;

  a {
    margin-left: 4.187rem;
    color: ${props => props.theme.color.gray};
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    a {
      display: none;
    }
  }
`;
