import React from "react";
import styled from "styled-components";

//components
import SectionHeader from "components/Contact/SectionHeader";
import SectionForm from "components/Contact/SectionForm";

const Contact = () => { 
  return (
    <Container>
      <SectionHeader/>
      <SectionForm/>
    </Container>
  );
}

export default Contact;

const Container = styled.div``;