import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./configureStyle";
import { theme } from "./theme";
import Routes from "./routes/index";
import history from "./utils/history";

const trackingId = "UA-48931823-3";
ReactGA.initialize(trackingId);

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes history={history} />
    </ThemeProvider>
  </>,
  document.getElementById("root")
);

if (process.env.NODE_ENV !== "production") {
  //@ts-ignore
  if (module.hot) {
    //@ts-ignore
    module.hot.accept();
  }
}
