import React from "react";
import styled from "styled-components";
import { Element } from "react-scroll";
import folha from "assets/icons/folha.svg";
import desenho from "assets/icons/desenho.svg";
import { getUrlImage } from "utils/images";

const GridOne = ({ handleItem, data }) => {
  return (
    <GridInline>
      {data?.map((item, idx) => (
        <div key={idx} className="container-items">
          <Element name={item?.title}>
            <BoxListTitle>
              <ListTitle dangerouslySetInnerHTML={{ __html: item?.title }} />
              {!!item?.description ? <span>{item?.description}</span> : null}
              {idx === 0 ? (
                <TypeFood mRight={"1.3rem"}>
                  <img src={folha} alt="folha" draggable={false} />
                  <span>Vegetariano</span>
                  <img src={folha} alt="folha" draggable={false} />
                  <img src={folha} alt="folha" draggable={false} />
                  <span>Vegano</span>
                </TypeFood>
              ) : null}
            </BoxListTitle>
            <List>
              {item?.childrens?.map((child, child_idx) => (
                <ListItem onClick={() => handleItem(child)} key={child_idx}>
                  <Column>
                    <div>
                      {child.title}
                      {child?.type === 1 ? (
                        <img src={folha} alt="folha" draggable={false} />
                      ) : child?.type === 2 ? (
                        <>
                          <img src={folha} alt="folha" draggable={false} />
                          <img src={folha} alt="folha" draggable={false} />
                        </>
                      ) : null}
                    </div>
                    <span>{child?.description}</span>
                  </Column>
                  {!!child?.image ? (
                    <img
                      src={getUrlImage(child?.title)}
                      alt="Aqui tem uma imagem"
                    />
                  ) : null}
                </ListItem>
              ))}
            </List>
          </Element>
        </div>
      ))}
      <img src={desenho} alt="desenho" className="desenho" draggable={false} />
    </GridInline>
  );
};

export default GridOne;

const GridInline = styled.div`
  max-width: ${props => props.theme.queries.xl};
  margin: 0 auto;
  position: relative;
  z-index: 9;
  display: grid;
  grid-template-columns: repeat(1, 100%);

  .container-items:nth-child(even) {
    justify-self: flex-end;
    margin-top: -140px;
  }

  .container-items:nth-child(odd) {
    margin-top: -140px;
  }

  .container-items:first-child {
    margin-top: 0;
  }

  .container-items {
    z-index: 9;
  }

  ul {
    max-width: 600px;
  }

  .desenho {
    position: absolute;
    top: 100px;
    left: 30px;
    max-width: 90%;
    opacity: 0.6;
  }

  @media (max-width: ${props => props.theme.queries.xl}) {
    padding: 0 1rem;
  }

  @media (min-width: ${props => props.theme.queries.md}) {
    div[name="Saladas"] {
      margin-top: 7rem;
    }
  }

  @media (max-width: ${props => props.theme.queries.md}) {
    ul {
      max-width: 350px;
    }

    .container-items:nth-child(even) {
      justify-self: flex-start;
    }
    .container-items {
      margin-top: 0 !important;
    }
    ul {
      max-width: 100%;
    }
  }
`;

//TITLE

const BoxListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  margin: 0 0 1rem 0;

  @media (max-width: ${props => props.theme.queries.sm}) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
`;

const ListTitle = styled.h2`
  text-align: left;
  font-size: 3.5rem;
  color: ${props => props.theme.color.green};
  font-family: "Classical Authentic";
  font-weight: 400;
  margin: 0;

  span {
    font-size: 2rem;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    font-size: 2.5rem;
    line-height: 1.1;

    span {
      font-size: 1.5rem;
    }
  }
`;

const TypeFood = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 55px;
    width: 55px;
  }

  span {
    margin-left: 1rem;
    margin-right: ${props => props.mRight};
    color: ${props => props.theme.color.paragraph};
    font-size: 1.25rem;
    text-transform: capitalize;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    margin-bottom: 2rem;
    img {
      height: 35px;
      width: 35px;
    }

    span {
      margin-left: 1rem;
      margin-right: ${props => props.mRight};
      font-size: 0.9rem;
    }
  }

  @media (max-width: ${props => props.theme.queries.i5}) {
    img {
      height: 25px;
      width: 25px;
    }

    span {
      margin-left: 0.5rem;
      margin-right: ${props => props.mRight};
      font-size: 0.9rem;
    }
  }
`;
//

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 3rem 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    display: block;
    width: auto;
    height: 104px;
    margin-left: 1rem;
    border-radius: 0.3rem;
  }

  /* @media (max-width: ${props => props.theme.queries.md}) {
    img {
      display: block;
    }
  } */
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  div {
    display: flex;
    align-items: baseline;
    color: ${props => props.theme.color.title};
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.3;
    font-family: "Classical Authentic";

    img {
      width: 30px;
      height: 25px;
      margin-left: 0.3rem;
      display: block !important;
    }
  }

  span {
    color: ${props => props.theme.color.paragraph};
    font-size: 1.25rem;
  }

  @media (max-width: ${props => props.theme.queries.sm}) {
    div {
      font-size: 1.275rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
`;
