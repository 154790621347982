import React from "react";
import styled from "styled-components";
import foods from "utils/foods.json";

//components
import Categories from "components/Home/Categories";
import Delivery from "components/Home/Delivery";
import Location from "components/Home/Location";

const Home = () => {
  const categoriesMostViewed = foods?.data?.filter(item => item.isMostviewed);

  return (
    <Container>
      <Categories data={categoriesMostViewed} />
      <Delivery />
      <Location />
    </Container>
  );
};

export default Home;

const Container = styled.div``;
