import React from "react";
import { SideSheet, ArrowLeftIcon } from "evergreen-ui";
import styled from "styled-components";
import { getUrlImage } from "utils/images";

interface IModel {
  show: boolean;
  onClose: any;
  data: any;
}

const ModalFoodDetail = ({ show, onClose, data }: IModel) => {
  return (
    <SideSheet onCloseComplete={onClose} width={"100%"} isShown={show}>
      <Container>
        <Header>
          <div>Detalhe do produto</div>
          <HeaderBackIcon size={32} onClick={onClose} />
        </Header>

        <Content>
          {!!data?.image ? (
            <Image url={getUrlImage(data?.title)} alt="Aqui tem uma imagem" />
          ) : null}
          <div>{data?.title}</div>
          <p>{data?.description}</p>
        </Content>
      </Container>
    </SideSheet>
  );
};

export default ModalFoodDetail;

const Container = styled.div`
  padding: 1rem;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  border-bottom: 1.5px solid #eee;
`;

const HeaderBackIcon = styled(ArrowLeftIcon)`
  position: absolute;
  left: 0;
`;

const Content = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  div {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1.375rem;
    color: ${props => props.theme.color.title};
  }

  p {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: ${props => props.theme.color.paragraph};
  }
`;

const Image = styled.div`
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
  height: 300px;
  border-radius: 0.3rem;
  background-color: #eee;

  @media (max-width: ${props => props.theme.queries.sm}) {
    height: 180px;
  }
`;
